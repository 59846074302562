import { alpha, Box, FormControlLabel, FormControlLabelProps, makeStyles, Radio, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { FC, ReactNode } from "react";

const BORDER_RADIUS_MULTIPLIER = 2;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      alignItems: "flex-start",
      border: "1px solid",
      borderColor: theme.colors.logo.darkness100,
      color: theme.colors.logo.darkness,
      padding: theme.spacing(1, 2, 1, 1),
      width: "100%",
      margin: 0,

      "& em": {
        backgroundColor: alpha(theme.colors.logo.darkness, 0.15),
        borderRadius: 4,
        padding: theme.spacing(0, 0.5),
        fontStyle: "normal",
      },

      "&:not(:last-of-type)": {
        borderBottom: "none",
      },
    },
    rootChecked: {
      backgroundColor: theme.colors.logo.darkness100,
    },
    details: {},
    header: {
      marginTop: theme.spacing(1.25),
      fontWeight: theme.typography.fontWeightRegular,
    },
    headerChecked: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    body: {
      opacity: 0.8,
      paddingBottom: theme.spacing(1),
    },
    radio: {},
    vertical: {
      "&:first-child": {
        borderRadius: theme.spacing(BORDER_RADIUS_MULTIPLIER, BORDER_RADIUS_MULTIPLIER, 0, 0),
      },
      "&:last-child": {
        borderRadius: theme.spacing(0, 0, BORDER_RADIUS_MULTIPLIER, BORDER_RADIUS_MULTIPLIER),
      },
    },
    horizontal: {
      borderRadius: theme.spacing(BORDER_RADIUS_MULTIPLIER),
    },
  }),
  {
    classNamePrefix: "RadioBox",
  }
);

export type RadioBoxJSSClassKey = keyof ReturnType<typeof useStyles>;

export type RadioBoxProps = Omit<FormControlLabelProps, "classes" | "children" | "label" | "control"> & {
  classes?: Partial<ClassNameMap<RadioBoxJSSClassKey>>;
  className?: string;
  checked?: boolean;
  header?: ReactNode;
  children?: ReactNode;
  formControlLabelClasses?: FormControlLabelProps["classes"];
  styleless?: boolean;
  orientation?: "vertical" | "horizontal";
};

export const RadioBox: FC<RadioBoxProps> = ({
  className,
  classes: extClasses,
  children,
  checked,
  header,
  formControlLabelClasses,
  styleless,
  orientation,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <FormControlLabel
      className={clsx(className, !styleless && classes.root, {
        [classes.vertical]: orientation === "vertical" && !styleless,
        [classes.horizontal]: orientation === "horizontal" && !styleless,
        [classes.rootChecked]: checked && !styleless,
      })}
      classes={formControlLabelClasses}
      label={
        <Box className={classes.details}>
          {!!header && (
            <Typography
              variant="h6"
              className={clsx(classes.header, {
                [classes.headerChecked]: checked,
              })}
            >
              {header}
            </Typography>
          )}
          <Box className={clsx(!styleless && classes.body)}>{children}</Box>
        </Box>
      }
      control={<Radio color="primary" className={classes.radio} checked={checked} />}
      {...rest}
    />
  );
};
